import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import slugify from '@sindresorhus/slugify';

// Components
import Layout from '../../components/layout/Layout';
import ShareBlock from '../../components/block/Share';
import ArticleCard from '../../components/card/Article';

// Article
const Article = ({ pageContext, data }) => {
  
  const {t} = useTranslation();

  const { languagePath } = pageContext;

  const pageImage = { 
    url: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.type : null,
    width: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.article.frontmatter.image.alt ? data.article.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "article",
      title: data.article.frontmatter.meta.title,
      description: data.article.frontmatter.meta.description,
      cover: data.article.frontmatter.image.cover,
      images: data.article.frontmatter.images,
      date_published: data.article.frontmatter.date_published,
      date_modified: data.article.frontmatter.date_modified,
      author_name: data.author ? data.author.frontmatter.name : null,
      author_url: data.author ? `${languagePath}/author/${data.author.frontmatter.path}/` : null,
      publisher_name: data.publisher ? data.publisher.frontmatter.name : null,
      publisher_url: data.publisher ? data.publisher.frontmatter.url : null,
      publisher_logo_url: data.publisher ? data.publisher.frontmatter.logo_url : null,
    },
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.articles'),
          item: languagePath + "articles/"
        },
        { 
          name: data.article.frontmatter.title
        },
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic"
      pageTitle={data.article.frontmatter.meta.title} 
      pageDescription={data.article.frontmatter.meta.description} 
      pageKeywords={data.article.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-2">
        <nav className="border-bottom" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/articles/`}>{t('breadcrumb.articles')}</Link></li>
            <li className="breadcrumb-item active">{data.article.frontmatter.title}</li>
          </ol>
        </nav>
        <div className="w-lg-75 content-space-1 mx-lg-auto">
          <div className="mb-4">
            {data.article.frontmatter.title &&
              <h1 className="h2">{data.article.frontmatter.title}</h1>
            }
          </div>
          <div className="row align-items-sm-center mb-2">
            {data.author ? (
              <div className="col-sm-7 mb-4 mb-sm-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <GatsbyImage
                      image={getImage(data.author.frontmatter.image.profile)}
                      className="avatar avatar-circle"
                      alt={data.author.frontmatter.image.alt}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-0">
                      {data.author.frontmatter.name &&
                        <Link to={`/author/${data.author.frontmatter.path}/`} className="text-dark">{data.author.frontmatter.name}</Link>
                      }
                    </h5>
                    {data.article.frontmatter.date_published &&
                      <span className="d-block">
                        <span className="small">{data.article.frontmatter.date_published}</span>
                        <span className="small ms-2">•</span>
                        <span className="small ms-2">{t('timeToRead', { time_to_read: data.article.timeToRead })}</span>
                      </span>
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-7 mb-4 mb-sm-0">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {data.article.frontmatter.date_published &&
                      <span className="d-block">
                        <span>{data.article.frontmatter.date_published}</span>
                        <span className="ms-2">•</span>
                        <span className="ms-2">{t('timeToRead', { time_to_read: data.article.timeToRead })}</span>
                      </span>                      
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-5">
              <div className="d-flex justify-content-sm-end align-items-center">
                <span className="text-cap mb-0 me-2">{t('share')}</span>
                <div className="d-flex gap-2">
                  <ShareBlock 
                    data={data.article} 
                    title={data.article.frontmatter.title} 
                    description={data.article.frontmatter.description} 
                    blockStyle="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-lg-75 border-top content-space-1 mx-lg-auto">
          <GatsbyImage
            image={getImage(data.article.frontmatter.image.cover)}
            className="my-4" imgClassName="img-fluid rounded-2 border"
            alt={data.article.frontmatter.image.alt}
          />
          <MDXProvider
            components={{
              h2: props => <h2 {...props} className="mt-5 mb-3">{props.children}</h2>,
              h3: props => <h3 {...props} className="mt-5 mb-3">{props.children}</h3>,
              h4: props => <h4 {...props} className="mt-5 mb-3">{props.children}</h4>,
              h5: props => <h5 {...props} className="mt-5 mb-3">{props.children}</h5>,
              a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
              Link: props => <Link {...props} className="text-highlight-warning">{props.children}</Link>,
            }}
          >
            <MDXRenderer
              localImages={data.article.frontmatter.images}
              >
              {data.article.body}
            </MDXRenderer>
          </MDXProvider>  
        </div>  
        <div className="w-lg-75 border-top content-space-1 mx-lg-auto">
          <p>
            <span>{t('followUsOn')} </span>
            {data.site.siteMetadata.socialNetworks.linkedIn ? 
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.linkedIn} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
                <span>, </span>
              </span>
            : null}            
            {data.site.siteMetadata.socialNetworks.facebook ? 
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.facebook} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
                <span>, </span>
              </span>
            : null} 
            {data.site.siteMetadata.socialNetworks.instagram ? 
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
                <span>, </span>
              </span>
            : null} 
            {data.site.siteMetadata.socialNetworks.twitter ? 
              <span>
                <a className="link" href={data.site.siteMetadata.socialNetworks.twitter} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
                <span>.</span>
              </span>
            : null}
          </p>
          {data.tags.group.length ? 
            <div className="mt-5">
              {data.tags.group.map(tag => (
                <Link key={tag.title} to={`/articles/tags/${slugify(tag.title)}/`} className="btn btn-soft-secondary btn-xs mb-1 me-1">
                  {t('article.tags.' + tag.title + '.label')}
                </Link>
              ))}
            </div>
          : null }
          <div className="row justify-content-sm-between align-items-sm-center mt-5">
            <div className="col-sm mb-2 mb-sm-0">
              <div className="d-flex align-items-center">
                <span className="text-cap mb-0 me-2">{t('share')}</span>
                <div className="d-flex gap-2">
                  <ShareBlock 
                    data={data.article} 
                    title={data.article.frontmatter.title} 
                    description={data.article.frontmatter.description} 
                    blockStyle="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {data.author &&
          <div className="w-lg-75 border-top content-space-1 mx-lg-auto">
            <div className="row">
              <div className="col-sm mb-2 mb-sm-0">
                <div className="mb-5">
                  <h4>{t('aboutAuthor')}</h4>
                </div>
                <div className="d-sm-flex">
                  <div className="flex-shrink-0 mb-3 mb-sm-0">
                    <GatsbyImage
                      image={getImage(data.author.frontmatter.image.profile)}
                      className="avatar avatar-xl avatar-circle"
                      alt={data.author.frontmatter.image.alt}
                    />                  
                  </div>
                  <div className="flex-grow-1 ms-sm-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">
                        {data.author.frontmatter.name &&
                          <Link to={`/author/${data.author.frontmatter.path}/`} className="text-dark">{data.author.frontmatter.name}</Link>
                        }
                      </h3>
                    </div>
                    {data.author.body &&
                      <div>
                        <MDXRenderer>
                          {data.author.body}
                        </MDXRenderer>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {data.relatedArticles.nodes.length ? 
          <div className="w-lg-75 border-top content-space-1 mx-lg-auto">
            <div className="mb-3 mb-sm-5">
              <h2>{t('relatedArticles')}</h2>
            </div>
            <div className="row">
              {data.relatedArticles.nodes.map(article => (
                <ArticleCard key={article.id} data={article} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4"/>
              ))}
            </div>
          </div>
        : null }
        {data.lastArticles.nodes.length ? 
          <div className="w-lg-75 border-top content-space-1 mx-lg-auto">
            <div className="mb-3 mb-sm-5">
              <h2>{t('lastArticles')}</h2>
            </div>
            <div className="row">
              {data.lastArticles.nodes.map(article => (
                <ArticleCard key={article.id} data={article} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4"/>
              ))}
            </div>
          </div>
        : null }
      </div>
    </Layout>
  )
}

Article.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    article: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export default Article

export const query = graphql`
  query ($id: String!, $category: String!, $author: String!, $publisher: String!, $language: String!) {
    site: site {
      siteMetadata {
        socialNetworks {
          linkedIn
          facebook
          messenger
          instagram
          twitter
        }
      }
    }
    article: mdx(
      fileAbsolutePath: { regex: "/data/articles/"}
      id: {eq: $id}, 
      frontmatter: { active: { eq: true }, language: {eq: $language} }
    ) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        title
        category
        tags
        description
        author
        publisher
        image {
          cover {
            childImageSharp {
              gatsbyImageData(width: 1600)
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        language
        path
        date_published(formatString: "D MMMM YYYY", locale: $language)
        date_modified(formatString: "D MMMM YYYY", locale: $language)
      }
      body
      timeToRead
    }
    tags: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        id: {eq: $id}, 
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___tags) {
        title: fieldValue
        totalCount
      }
    }
    author: mdx(
      fileAbsolutePath: { regex: "/data/authors/"}
      frontmatter: { path: {eq: $author}, active: { eq: true }, language: {eq: $language} }
    ) {
      frontmatter {
        name
        description
        language
        image {
          profile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        home
        active
        path
        language
        date
      }
      id
      body
    }
    publisher: mdx(
      fileAbsolutePath: { regex: "/data/publishers/"}
      frontmatter: { path: {eq: $publisher}, active: { eq: true }, language: {eq: $language} }
    ) {
      frontmatter {
        name
        description
        language
        url
        logo_url
        image {
          logo {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          cover {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        home
        active
        path
        language
        date
      }
      id
      body
    }
    relatedArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        id: {ne: $id},
        frontmatter: { category: {eq: $category}, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          description
          author
          publisher
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    lastArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        id: {ne: $id},
        frontmatter: { category: {ne: $category}, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          description
          author
          publisher
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "article", "categories", "tags", "share-on", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`